import { StyledCard } from 'components/Custom/card';
import { ScopeTable } from 'components/Custom/table';
import * as React from 'react';
import { DataContact } from 'store/vat/models/vatReportData';
interface TableDataContact extends DataContact {
  key: number;
}
export const renderMaltaRecap = (contacts?: DataContact[]) => {
  const tableContacts: TableDataContact[] = [];
  contacts?.forEach((c, key) => tableContacts.push({ ...c, key }));
  const columns = [
    {
      title: 'Triangular Trade',
      render: (_, record: DataContact) => {
        return record.isTriangularTrade ? 'YES' : 'NO';
      },
    },
    {
      title: 'Customer Name',
      dataIndex: 'name',
    },
    {
      title: 'Country',
      dataIndex: 'country',
    },
    {
      title: 'VAT Number',
      dataIndex: 'taxNumber',
    },
    {
      title: 'VAT Verified',
      dataIndex: 'viesStatus',
    },
    {
      title: 'Goods Subtotal',
      dataIndex: 'goodsSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.goodsSum.toFixed(2);
      },
    },
    {
      title: 'Services Subtotal',
      dataIndex: 'servicesSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.servicesSum.toFixed(2);
      },
    },
  ];

  return (
    <StyledCard //@ts-ignore
      width="100%"
    >
      <div
        style={{
          padding: '15px',
          paddingLeft: '5px',
          paddingTop: '0px',
          //maxWidth: 1600,
          minWidth: 800,
          margin: 'auto',
        }}
      >
        <ScopeTable
          filters={[]}
          className="recap-statement"
          originalDataSource={tableContacts} //@ts-ignore
          tableDataSource={tableContacts}
          pagination={true}
          rowsPerPage={10}
          enableColumnsCustomization={false}
          rowHeight={35}
          columns={columns}
          titleComponent={null}
          showSizeChanger
        />
      </div>
    </StyledCard>
  );
};
